// src/components/ComingSoon.tsx
import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import { Container, Stack } from "@mui/material";

// ComingSoonコンポーネントの定義
const ComingSoon: React.FC = () => {
  return (
    <div>
      <Header pageTitle="" />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh", // 画面の80%の高さで中央に寄せる
          textAlign: "center", // テキストの中央揃え
          marginTop: "16px",
          marginBottom: "100px",
        }}
      >
        <h1>近日リリース予定です</h1>
        <p>
          現在システムを作成中です。
          <br />
          今しばらくお待ちくださいますよう宜しくお願い申し上げます。
        </p>
      </Container>
      <Footer />
    </div>
  );
};

export default ComingSoon;
