import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { toJSTISOString } from "../../components/Utils/utils";

export interface customerInfoState {
  email: string;
  pic: string;
  picRuby: string;
  companyName: string;
  companyNameRuby: string;
  postalCode: string;
  address: string;
  address2: string;
  phoneNumber: string;
  deliveryDate: string;
  projectName: string;
  remarks: string;
  status: "loading" | "succeeded" | "failed" | string;
}
const initialState: customerInfoState = {
  email: "",
  pic: "",
  picRuby: "",
  companyName: "",
  companyNameRuby: "",
  postalCode: "",
  address: "",
  address2: "",
  phoneNumber: "",
  deliveryDate: "",
  projectName: "",
  remarks: "",
  status: "loading",
};
export const customerInfoSlice = createSlice({
  name: "customerInfoSlice",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<customerInfoState>>) => {
      // Update each field in the state with new values from the action's payload if they exist
      state.email = action.payload.email ?? state.email;
      state.pic = action.payload.pic ?? state.pic;
      state.picRuby = action.payload.picRuby ?? state.picRuby;
      state.companyName = action.payload.companyName ?? state.companyName;
      state.companyNameRuby =
        action.payload.companyNameRuby ?? state.companyNameRuby;
      state.postalCode = action.payload.postalCode ?? state.postalCode;
      state.address = action.payload.address ?? state.address;
      state.address2 = action.payload.address2 ?? state.address2;
      state.phoneNumber = action.payload.phoneNumber ?? state.phoneNumber;
      state.deliveryDate = action.payload.deliveryDate ?? state.deliveryDate;
      state.projectName = action.payload.projectName ?? state.projectName;
      state.remarks = action.payload.remarks ?? state.remarks;
      state.status = action.payload.status ?? state.status;
    },
  },
});

export const { update } = customerInfoSlice.actions;
export const selectCustomerInfo: any = createSelector(
  (state: RootState) => state.customerInfo,
  (customerInfo: customerInfoState) => ({
    ...customerInfo,
    deliveryDate: customerInfo.deliveryDate
      ? toJSTISOString(new Date(customerInfo.deliveryDate))
      : null, // deliveryDateがない場合はnullを設定
  })
);
export default customerInfoSlice.reducer;
