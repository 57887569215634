// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// pages
import CommingSoon from "./pages/CommingSoon";
import Home from "./pages/Home";
import InputCustomerInfo from "./pages/InputCustomerInfo";
import InputInfoConfirmation from "./pages/InputInfoConfirmation";
import EstimationDisplay from "./pages/EstimationDisplay";
import OrderAcceptanceCompletion from "./pages/OrderAcceptanceCompletion";
import TestPage from "./pages/TestPage";
// components

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CommingSoon />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/test-page" element={<TestPage />}></Route>
        <Route
          path="/input-customer-info"
          element={<InputCustomerInfo />}
        ></Route>
        <Route
          path="/input-info-confirmation"
          element={<InputInfoConfirmation />}
        ></Route>
        <Route
          path="/estimation-display"
          element={<EstimationDisplay />}
        ></Route>
        <Route
          path="/order-acceptance-completion"
          element={<OrderAcceptanceCompletion />}
        ></Route>
      </Routes>
    </Router>
  );
};

export default App;
