import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { estimationAmountState } from "../estimationAmount/estimationAmountSlice";
import { createSelector } from "reselect";

// 初期状態の定義
const initialState: estimationAmountState[] = [];

export const estimationAmountListSlice = createSlice({
  name: "estimationAmountList",
  initialState,
  reducers: {
    addEstimationToList: (
      state,
      action: PayloadAction<estimationAmountState>
    ) => {
      // 新しい値を取得
      const newValue: estimationAmountState = {
        ...action.payload,
        status: "succeeded", // statusを成功として設定
      };
      // 新しい配列を作成して追加
      // state = [...state, newValue];
      // 新しい状態を返す（Redux Toolkitのimmerによって、直接状態を変更できないため）
      return [...state, newValue];
    },
    removeEstimationFromList: (state, action: PayloadAction<number>) => {
      // 受け取ったインデックスを使って、そのインデックスの要素を除外した新しい配列を作成
      return state.filter((_, index) => index !== action.payload);
    },
    toggleCheckBox: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state[index] = {
        ...state[index],
        checkBox: !state[index].checkBox,
      };
    },
    toggleCheckBoxAll: (state, action: PayloadAction<boolean>) => {
      const checkStatus = action.payload;
      return state.map((item) => ({
        ...item,
        checkBox: checkStatus,
      }));
    },
  },
});
// 読み出し用
export const selectEstimationAmountList = (state: RootState) =>
  state.estimationAmountList;
export const selectCheckedEstimationAmountList = createSelector(
  [selectEstimationAmountList],
  (estimationAmountList) => {
    return estimationAmountList.filter((item) => item.checkBox);
  }
);
// action用
export const {
  addEstimationToList,
  removeEstimationFromList,
  toggleCheckBox,
  toggleCheckBoxAll,
} = estimationAmountListSlice.actions;
export default estimationAmountListSlice.reducer;
