import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Container,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";

// カスタイマイズしたもの
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { theme } from "../components/Color/color";
import logo from "../images/rakuCADtrace_logo.png";
import trimIn from "../images/trim_in.png";
import {
  formatCurrency,
  getFormattedToday,
  getformatDateFromISOFormat,
  generateUUID,
  getCurrentDateInJST,
} from "../components/Utils/utils";
// ローディング
import LoadingDialog from "../components/LoadingDialog/LoadingDialog";

// redux
import { selectCheckedEstimationAmountList } from "../features/estimationAmountList/estimationAmountListSlice";
import {
  selectManagementProcess,
  managementProcess,
} from "../features/managementProcess/managementProcessSlice";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectCustomerInfo,
  customerInfoState,
} from "../features/customerInfo/customerInfoSlice";

// 関数などの定義

// 見積もりアイテムのデータ型
interface Data {
  itemNo: string;
  description: string;
  quantity: string;
  unit: string;
  unitPrice: string;
  amount: string;
  s3EstimateTargetImageFileURL: string;
}
//　見積もりアイテムのデータ作成関数
const createItemData = (
  itemNo: string,
  description: string,
  quantity: string,
  unit: string,
  unitPrice: string,
  amount: string,
  s3EstimateTargetImageFileURL: string
): Data => {
  return {
    itemNo,
    description,
    quantity,
    unit,
    unitPrice,
    amount,
    s3EstimateTargetImageFileURL,
  };
};

// ゼロパディングのための関数
const zeroPad = (num: number, places: number): string =>
  String(num).padStart(places, "0");

const EstimationDisplay: React.FC = () => {
  // ナビゲーション
  const navigate = useNavigate();
  // redux
  const checkedEstimationAmountList = useAppSelector(
    selectCheckedEstimationAmountList
  );
  const customerInfo: customerInfoState = useAppSelector(selectCustomerInfo);
  const managementProcess: managementProcess = useAppSelector(
    selectManagementProcess
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (managementProcess.display === "OrderAcceptanceCompletion") {
      // オーダー完了後に遷移してきた場合
      alert("不正なページ遷移です");
      // ホームへ遷移
      navigate("/");
    }
  }, [dispatch, managementProcess.display, navigate]);

  // 見積ID(DB上のID)
  const estimationIdRef = useRef<string | null>(null);
  if (estimationIdRef.current === null) {
    estimationIdRef.current = generateUUID();
  }

  // 見積番号
  const [estimationNumber, setEstimationNumber] = useState<string>("");
  // 小計
  const subtotal = checkedEstimationAmountList.reduce((sum: number, data) => {
    return sum + (data.estimationAmount || 0);
  }, 0);
  // 税金
  const tax = subtotal * 0.1;
  // 合計
  const total = subtotal + tax;
  // テーブルデータ
  const rows: Data[] = checkedEstimationAmountList.map((data, index) =>
    createItemData(
      zeroPad(index + 1, 4),
      data.estimationFileName || "",
      "1",
      "枚",
      data.estimationAmount?.toString() || "0",
      data.estimationAmount?.toString() || "0",
      data.estimationS3FileName?.toString() || ""
    )
  );

  // 見積の読み込み
  const postEstimate = async (data_json: any): Promise<string> => {
    let response: any;
    try {
      response = await axios.post(
        "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/create-estimation-number",
        data_json,
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      );
      // console.log("Success", response);
    } catch (error) {
      console.log("Error", error);
    }
    return response;
  };

  // ローディング画面の処理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 読み込み時に必要な事前処理
        // ここで顧客情報部分のデータを作る
        const customerInfoData = {
          id: estimationIdRef.current,
          email: customerInfo.email,
          pic: customerInfo.pic,
          picRuby: customerInfo.picRuby,
          companyName: customerInfo.companyName,
          companyNameRuby: customerInfo.companyNameRuby,
          postalCode: customerInfo.postalCode,
          address: customerInfo.address,
          address2: customerInfo.address2,
          phoneNumber: customerInfo.phoneNumber,
          deliveryDate: customerInfo.deliveryDate,
          projectName: customerInfo.projectName,
          remarks: customerInfo.remarks,
          subtotal: subtotal,
          consumptionTax: tax,
          totalAmount: total,
        };
        // 見積アイテムのデータ配列を作る
        const items = rows.map((item) => ({
          id: generateUUID(),
          estimationListId: estimationIdRef.current,
          itemNo: item.itemNo,
          description: item.description,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          amount: item.amount,
          s3EstimateTargetImageFileURL: item.s3EstimateTargetImageFileURL,
        }));
        const data = {
          customerInfoData,
          items,
        };
        // console.log(updatedEstimations);

        // jsonコードに変換
        const data_json = JSON.stringify(data);
        // 見積の送信と見積番号の取得
        const response_data: any = await postEstimate(data_json);
        const json_obj = JSON.parse(response_data["data"]);
        setEstimationNumber(json_obj.new_estimation_number);
      } catch (error) {
        console.error("Error fetching disabled dates:", error);
      } finally {
        setIsLoading(false);
        // 注文の場合は見積画面上の動作を飛ばして最終確認へ
        if (managementProcess.process === "order") {
          placeAnOrder();
        }
      }
    };
    fetchData();
  }, [
    customerInfo.address,
    customerInfo.address2,
    customerInfo.companyName,
    customerInfo.companyNameRuby,
    customerInfo.deliveryDate,
    customerInfo.email,
    customerInfo.phoneNumber,
    customerInfo.pic,
    customerInfo.picRuby,
    customerInfo.postalCode,
    customerInfo.projectName,
    customerInfo.remarks,
    managementProcess.process,
    rows,
    subtotal,
    tax,
    total,
  ]);

  // 出力したい見積もり要素を参照するためのref
  const contentRef = useRef<HTMLDivElement>(null);

  // PDF生成関数
  const generatePDF = async () => {
    if (contentRef.current) {
      // 高解像度でキャプチャするためのスケールを設定
      const scale = 2; // スケール値を適宜調整
      // html2canvasを使用して指定要素をキャプチャ
      const canvas = await html2canvas(contentRef.current, {
        scale: scale,
        useCORS: true, // Cross-origin リソースをキャプチャする場合
      });
      const imgData = canvas.toDataURL("image/png");

      // A4サイズのPDFを作成 (210 x 297 mm)
      const pdf = new jsPDF({
        orientation: "portrait", // 'portrait' or 'landscape'
        unit: "mm",
        format: "a4",
      });

      // 画像のプロパティを取得
      const imgProps = pdf.getImageProperties(imgData);

      // PDFのサイズを設定
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // キャプチャした内容をPDFに追加
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const fileName = `${getCurrentDateInJST()}_${estimationNumber}_rakuCAD.pdf`;
      pdf.save(fileName);
    }
  };

  // 見積もり表示テーマ
  const estimationTheme = createTheme({
    typography: {
      // h1: {
      //   fontSize: '2.5rem',
      //   fontWeight: 700,
      // },
      h2: {
        fontSize: "2.5rem",
        // fontWeight: 600,
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
      // 他のスタイルも追加可能
    },
  });

  //////////////////////////////////////////////////
  // 発注処理
  //////////////////////////////////////////////////

  // 発注登録API
  const postPlaceAnOrder = async (data_json: any): Promise<string> => {
    let response: any;
    try {
      response = await axios.post(
        "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/place-an-order",
        data_json,
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      );
      // console.log("Success", response);
    } catch (error) {
      console.log("Error", error);
    }
    return response;
  };

  //メール送信API実行自社側
  const postSendNotificationMail = async (data_json: any): Promise<string> => {
    let response: any;
    try {
      response = await axios.post(
        "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/send-notification-mail",
        data_json,
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      );
      // console.log("Success", response);
    } catch (error) {
      console.log("Error", error);
    }
    return response;
  };

  //メール送信API実行ユーザー側用
  const postSendNotificationMailToUser = async (
    data_json: any
  ): Promise<string> => {
    let response: any;
    try {
      response = await axios.post(
        "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/send-notification-mail-to-user",
        data_json,
        {
          headers: {
            "Content-Type": "application/json",
            // Accept: "application/json",
          },
        }
      );
      // console.log("Success", response);
    } catch (error) {
      console.log("Error", error);
    }
    return response;
  };

  const [open, setOpen] = useState(false);
  // 発注ポップアップ起動
  const placeAnOrder = () => {
    setOpen(true);
  };
  // ダイアログを閉じる関数
  const handleClose = () => {
    setOpen(false);
  };
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const placeAnOrderConfirm = async () => {
    setOpen(false);
    // ローディング画面を表示
    setIsProcessing(true);
    try {
      //　ここでオーダーを作成しAPIをコール
      const order = {
        id: generateUUID(),
        estimationListId: estimationIdRef.current,
      };
      // jsonコードに変換
      const order_json = JSON.stringify(order);
      // 見積の送信と見積番号の取得
      const placeAnOrderResponse: any = await postPlaceAnOrder(order_json);
      const placeAnOrderResponseData: any = placeAnOrderResponse.data;
      console.log(placeAnOrderResponseData);
      if (placeAnOrderResponseData["status"] === "success") {
        console.log("オーダー登録完了");
      } else if (placeAnOrderResponseData["status"] === "error") {
        console.log("オーダー登録失敗");
      }
      // postデータオブジェクトの作成
      const postSendNotificationMailObj = {
        estimation: placeAnOrderResponseData["estimation"],
        items: placeAnOrderResponseData["items"],
        order: placeAnOrderResponseData["order"],
      };
      // jsonコードに変換
      const postSendNotificationMailJson = JSON.stringify(
        postSendNotificationMailObj
      );
      // メール送信実行
      const sendNotificationMailResponse: any = await postSendNotificationMail(
        postSendNotificationMailJson
      );
      // 顧客側も実行
      const sendNotificationMailToUserResponse: any =
        await postSendNotificationMailToUser(postSendNotificationMailJson);

      // それぞれのレスポンスを取得
      const sendNotificationMailResponseData: any =
        sendNotificationMailResponse.data;
      const sendNotificationMailToUserResponseData: any =
        sendNotificationMailToUserResponse.data;

      if (
        sendNotificationMailResponseData["status"] === "success" &&
        sendNotificationMailToUserResponseData["status"] === "success"
      ) {
        setIsProcessing(false);
        navigate("/order-acceptance-completion");
      } else if (
        sendNotificationMailResponseData["status"] === "error" ||
        sendNotificationMailToUserResponseData["status"] === "error"
      ) {
        // エラー時の処理
        setIsProcessing(false);
      }
    } catch (error) {
      console.log("Error", error);
      setIsProcessing(false);
    } finally {
      //最終処理
    }
  };

  return (
    <>
      <LoadingDialog isOpen={isLoading} message={"読込中"} />
      <LoadingDialog isOpen={isProcessing} message={"処理中"} />
      <Header pageTitle="見積確認" />
      <Container style={{ marginTop: "16px", marginBottom: "100px" }}>
        <Box
          sx={{
            margin: "20px 200px 10px 200px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <ThemeProvider theme={theme}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={generatePDF}
              >
                見積ダウンロード
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={placeAnOrder}
              >
                発注
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  //ダウンロード処理
                  navigate("/input-info-confirmation");
                }}
              >
                キャンセル
              </Button>
            </ThemeProvider>
          </Stack>
        </Box>
        <ThemeProvider theme={estimationTheme}>
          <Box
            sx={{
              marginTop: "20px",
              width: "100%",
              height: "100%",
              border: "1px solid black", // ボーダーのスタイルを指定
              padding: 0,
            }}
          >
            <div style={{ padding: "30px" }} ref={contentRef}>
              {/* 見積書タイトル */}
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography variant="h2" sx={{ margin: "20px 0px" }}>
                  お 見 積 書
                </Typography>
              </Box>

              {/* 分割線 */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "10px",
                }}
              >
                <Box sx={{ flex: "0 0 20%", backgroundColor: "#000000" }} />
                <Box sx={{ flex: "0 0 60%", backgroundColor: "#3964B1" }} />
                <Box sx={{ flex: "0 0 20%", backgroundColor: "#000000" }} />
              </Box>

              {/* 情報欄 */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginTop: "20px",
                  height: "100%",
                }}
              >
                {/* 左ブロック*/}
                <Box
                  sx={{
                    width: "55%",
                    height: "100%",
                    // backgroundColor: "green",
                    textAlign: "center",
                  }}
                >
                  {/* 会社名 */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      height: "100%",
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                    }}
                  >
                    <Box sx={{ flex: "0 0 80%", textAlign: "left" }}>
                      <Typography variant="h5">
                        {customerInfo.companyName}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 20%", textAlign: "left" }}>
                      <Typography>御中</Typography>
                    </Box>
                  </Box>
                  {/* 担当者名 */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      height: "100%",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box sx={{ flex: "0 0 80%", textAlign: "left" }}>
                      <Typography variant="h5">{customerInfo.pic}</Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 20%", textAlign: "left" }}>
                      <Typography>様</Typography>
                    </Box>
                  </Box>
                  {/* 郵便番号 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                      textAlign: "left",
                    }}
                  >
                    <Typography>〒{customerInfo.postalCode}</Typography>
                  </Box>
                  {/* 住所 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="h6">{customerInfo.address}</Typography>
                  </Box>
                  {/* マンション等 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="h6">
                      {customerInfo.address2}
                    </Typography>
                  </Box>
                  {/* 以下の通りお見積り申し上げます。 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                      textAlign: "left",
                    }}
                  >
                    <Typography>以下の通りお見積り申し上げます。</Typography>
                  </Box>
                  {/* 見積もり金額 */}
                  <Box
                    sx={{
                      display: "flex",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 35%",
                        backgroundColor: "#000000",
                        padding: "14px",
                      }}
                    >
                      <Typography color={"#ffffff"}>
                        お見積り金額（税込）
                      </Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 5%", backgroundColor: "#3964B1" }}>
                      <Typography
                        variant="h4"
                        color={"#ffffff"}
                        sx={{ marginTop: "5px" }}
                      >
                        ￥
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 35%",
                        backgroundColor: "#3964B1",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        variant="h4"
                        color={"#ffffff"}
                        sx={{ marginTop: "5px" }}
                      >
                        {formatCurrency(total)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 納期 */}
                  <Box
                    sx={{
                      display: "flex",
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 25%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #ffffff",
                      }}
                    >
                      <Typography color={"#ffffff"}>納期</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 46%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography color={"#000000"}>
                        {getformatDateFromISOFormat(customerInfo.deliveryDate)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 支払条件 */}
                  <Box
                    sx={{
                      display: "flex",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 25%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #ffffff",
                      }}
                    >
                      <Typography color={"#ffffff"}>支払条件</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 46%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography color={"#000000"}>
                        月末締め翌月末払い（銀行振込）
                      </Typography>
                    </Box>
                  </Box>
                  {/* 有効期限 */}
                  <Box
                    sx={{
                      display: "flex",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 25%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                      }}
                    >
                      <Typography color={"#ffffff"}>有効期限</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 46%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography color={"#000000"}>発行より1ヶ月</Typography>
                    </Box>
                  </Box>
                </Box>
                {/* 右ブロック*/}
                <Box sx={{ width: "50%", height: "100%" }}>
                  {/* 見積日 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "baseline",
                      // backgroundColor: "aqua",
                      marginTop: "12px",
                    }}
                  >
                    <Box sx={{ flex: "0 0 15%", textAlign: "left" }}>
                      <Typography>見積日</Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 50%", textAlign: "center" }}>
                      <Typography>{getFormattedToday()}</Typography>
                    </Box>
                  </Box>
                  {/* 見積番号 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "baseline",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box sx={{ flex: "0 0 15%", textAlign: "left" }}>
                      <Typography>見積番号</Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 50%", textAlign: "center" }}>
                      <Typography>{estimationNumber}</Typography>
                    </Box>
                  </Box>
                  {/* 登録番号 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "baseline",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box sx={{ flex: "0 0 15%", textAlign: "left" }}>
                      <Typography>登録番号</Typography>
                    </Box>
                    <Box sx={{ flex: "0 0 50%", textAlign: "center" }}>
                      <Typography>T7-1800-0111-6804</Typography>
                    </Box>
                  </Box>
                  {/* ロゴエリア */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      marginTop: "102px",
                    }}
                  >
                    <Box sx={{ flex: "0 0 65%" }}>
                      {/* ロゴ画像 */}
                      <Box
                        sx={
                          {
                            // backgroundColor: "aqua",
                          }
                        }
                      >
                        <img
                          src={logo}
                          alt="raku cad trace"
                          style={{ height: 80, marginLeft: 0, marginBottom: 0 }}
                        />
                      </Box>
                      {/* 運営会社 */}
                      {/* <Box
                        sx={{
                          // backgroundColor: "aqua",
                          textAlign: "center",
                        }}
                      >
                        <Typography>(運営会社：株式会社トリム)</Typography>
                      </Box> */}
                    </Box>
                    {/* 捺印 */}
                    <Box sx={{ flex: "0 0 20%" }}>
                      <img src={trimIn} alt="trim in" style={{ height: 120 }} />
                    </Box>
                  </Box>
                  {/* 会社名 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>株式会社トリム</Typography>
                  </Box>
                  {/* 郵便番号（自社） */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>〒461-0025</Typography>
                  </Box>
                  {/* 住所（自社） */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>
                      名古屋市東区徳川1丁目15-30 名古屋リザンビル9F
                    </Typography>
                  </Box>
                  {/* TEL（自社） */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginTop: "20px",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>TEL:052-739-6832</Typography>
                  </Box>
                  {/* メールアドレス */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>rakucad@cad-trace.jp</Typography>
                  </Box>
                  {/* 営業時間 */}
                  <Box
                    sx={{
                      // backgroundColor: "aqua",
                      marginLeft: "120px",
                    }}
                  >
                    <Typography>営業時間／平日 9:00~17:00</Typography>
                  </Box>
                </Box>
              </Box>

              {/* 案件名欄 */}
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "50px",
                    marginTop: "60px",
                  }}
                >
                  <Box sx={{ flex: "0 0 15%", backgroundColor: "#000000" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Typography variant="h6" color={"#ffffff"}>
                        案件名
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{ flex: "0 0 85%", borderBottom: "2px solid black" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        height: "100%",
                      }}
                    >
                      <Typography variant="h6" sx={{ padding: "0px 20px" }}>
                        {customerInfo.projectName}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* 見積もりアイテムテーブル */}
              <Box sx={{ marginTop: "30px" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#000000", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>品番</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#3964B1", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>内容</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#000000", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>数量</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#3964B1", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>単位</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#000000", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>単価(税抜)</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#3964B1", padding: "6px" }}
                        >
                          <Typography color={"#ffffff"}>金額(税抜)</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row.itemNo}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "white" : "grey.200",
                          }}
                        >
                          <TableCell align="center">{row.itemNo}</TableCell>
                          <TableCell align="center">
                            {row.description}
                          </TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">{row.unit}</TableCell>
                          <TableCell align="center">{row.unitPrice}</TableCell>
                          <TableCell align="center">{row.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {/* ※と小計～合計 */}
              <Box sx={{ display: "flex" }}>
                {/* ※ */}
                <Box sx={{ flex: "0 0 60%" }}>
                  <Box>
                    <Typography>
                      ※追加修正は別途料金が発生致します。詳細はお問合せください。
                    </Typography>
                    <Typography>
                      ※内容等変更が生じる場合は再見積もり致します。
                    </Typography>
                  </Box>
                </Box>
                {/* 小計-合計 */}
                <Box sx={{ flex: "0 0 40%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 30%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #ffffff",
                      }}
                    >
                      <Typography
                        color={"#ffffff"}
                        sx={{ textAlign: "center" }}
                      >
                        小計
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 41%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        color={"#000000"}
                        sx={{ textAlign: "center" }}
                      >
                        {formatCurrency(subtotal)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 消費税 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 30%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #ffffff",
                      }}
                    >
                      <Typography
                        color={"#ffffff"}
                        sx={{ textAlign: "center" }}
                      >
                        消費税
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 41%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        color={"#000000"}
                        sx={{ textAlign: "center" }}
                      >
                        {formatCurrency(tax)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 合計 */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      // backgroundColor: "aqua",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "0 0 30%",
                        backgroundColor: "#000000",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                      }}
                    >
                      <Typography
                        color={"#ffffff"}
                        sx={{ textAlign: "center" }}
                      >
                        合計
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "0 0 41%",
                        backgroundColor: "#ffffff",
                        padding: "14px",
                        borderBottom: "2px solid #000000",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        color={"#000000"}
                        sx={{ textAlign: "center" }}
                      >
                        {formatCurrency(total)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* 但し書き */}
              <Box
                sx={{
                  height: "200px",
                  backgroundColor: "#E1E1E1",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Typography>
                  本見積もり書は、図面トレースの金額です。
                </Typography>
                <Typography>付帯業務などは別途お見積りいたします。</Typography>
                <Typography>
                  詳細確認のため、メールまたは電話にて連絡する場合があります。
                </Typography>
                <Typography>
                  rakuCADtraceは株式会社トリムが運営しています。
                </Typography>
                <Typography>
                  ご不明な点がございましたらお問い合わせください。
                </Typography>
              </Box>
              {/* rakuCADtraceロゴ */}
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <Box
                  sx={
                    {
                      // backgroundColor: "aqua",
                    }
                  }
                >
                  <img
                    src={logo}
                    alt="raku cad trace"
                    style={{ height: 60, marginLeft: 0, marginBottom: 0 }}
                  />
                </Box>
              </Box>
              {/* 運営会社 */}
              {/* <Box sx={{ textAlign: "center" }}>
                <Typography>（運営会社：株式会社トリム）</Typography>
              </Box> */}
            </div>
          </Box>
        </ThemeProvider>

        <Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="order-confirmation-dialog-title"
            aria-describedby="order-confirmation-dialog-description"
          >
            <DialogTitle id="order-confirmation-dialog-title">
              最終確認
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="order-confirmation-dialog-description">
                <Typography component="span">
                  発注内容にお間違いありませんか？
                </Typography>
                <Typography component="span">
                  『発注確定』を押すと発注が確定します。
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={placeAnOrderConfirm} color="primary" autoFocus>
                発注確定
              </Button>
              <Button onClick={handleClose} color="secondary">
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default EstimationDisplay;
